import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";
import { Button } from "@material-ui/core";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 94;
  }

  public createContent(): any {
    const res = new FormModel.HomonKangoChukanSummary.Core();
    res.values.create_date = DateUtil.currentDateStr();

    HokanApi.me()
      .then((x) => {
        if (x && x.data) {
          res.values.sakuseisya = x.data.name;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  private intSort(a: Model.Intervention, b: Model.Intervention) {
    let toN = (c: string) => {
      if (c == "TGC") return 1;
      if (c == "CM") return 2;
      if (c == "TP") return 3;
      if (c == "S") return 4;
      return 5;
    };
    let ac = toN(a.category);
    let bc = toN(b.category);
    if (ac < bc) return -1;
    if (ac > bc) return +1;
    if (a.id < b.id) return -1;
    if (a.id > b.id) return +1;
    return 0;
  }

  private catToJp(cat: string) {
    if (cat == "TGC") return "TGC(教育/相談/指導)";
    if (cat == "CM") return "CM(ケアマネジメント)";
    if (cat == "TP") return "TP(直接ケア)";
    if (cat == "S") return "S(観察)";
    return "";
  }

  private loadFromBranch(doc: Model.Document.Document, sa: StoreAccessor) {
    if (!doc.patient) {
      alert("利用者を選択してください");
      return;
    }
    const content = doc.content as FormModel.HomonKangoChukanSummary.Core;
    const values = content.values;
    HokanApi.branch
      .get(doc.patient.branch_id)
      .then((res) => {
        values.jigyousyo = res.data.name || "";
        values.jigyousyo_address = res.data.address || "";
        values.jigyousyo_phone = res.data.tel || "";
        values.jigyousyo_fax = res.data.fax || "";
        sa.updateState();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private async loadFromAssessment(
    doc: Model.Document.Document,
    sa: StoreAccessor
  ) {
    if (!doc.patient) {
      alert("利用者を選択してください");
      return;
    }
    const current = doc.content as FormModel.Keikakusho.Core;
    HokanApi.getPatientAssessments(doc.patient.id)
      .then(async (_assList) => {
        if (doc.patient == null) return;

        let latestDocDate: string | null = null;
        let latestAssessment: Model.Assessment.AssessmentListResult[] = [];
        try {
          const latestDoc = await HokanApi.getDocumentLatest(
            doc.patient.id,
            this.getId(),
            current.values.create_date ? current.values.create_date : undefined
          );
          latestDocDate =
            latestDoc &&
            latestDoc.data &&
            latestDoc.data.content &&
            latestDoc.data.content.values
              ? latestDoc.data.content.values.create_date
              : undefined;
          if (latestDocDate) {
            latestAssessment = (
              await HokanApi.getPatientAssessments(
                doc.patient.id,
                latestDocDate
              )
            ).data;
          }
        } catch {
          console.log("no latest doc");
        }

        const content = doc.content as FormModel.Keikakusho.Core;
        const values = content.values;
        const x = _assList.data;
        if (x != null) {
          values.problem_date = "";
          values.problem = "";
          values.problem_ass = "";

          x.sort((a, b) => {
            if (
              a.oldest.assessment.assessment_day <
              b.oldest.assessment.assessment_day
            )
              return -1;
            if (
              a.oldest.assessment.assessment_day >
              b.oldest.assessment.assessment_day
            )
              return +1;
            return 0;
          }).forEach((x) => {
            const la = latestAssessment.find(
              (la) => la.problem.id == x.problem.id
            );
            const closeNow =
              x.newest.assessment.closed &&
              la &&
              la.newest.assessment.closed == false;
            if (
              x.newest.assessment.priority == 1 &&
              (x.newest.assessment.closed == false || closeNow)
            ) {
              values.problem_date +=
                "# " +
                x.problem.name +
                "\n" +
                x.oldest.assessment.assessment_day +
                "\n\n\n";
              let sortedIntv = x.newest.intervention.sort(this.intSort);
              let prev = "";
              let intvStr = sortedIntv
                .map((it) => {
                  let t =
                    prev != it.category
                      ? "\n" + this.catToJp(it.category) + ": "
                      : ", ";
                  prev = it.category;
                  if (it.target == "その他") {
                    let z = x.newest.assessment_intervention.find(
                      (ai) => ai.intervention_id == it.id
                    );
                    return t + (z && z.comment != "" ? z.comment : "その他");
                  } else {
                    return t + it.target;
                  }
                })
                .reduce((p, c) => p + c, "");
              values.problem +=
                "# " +
                x.problem.name +
                "\n" +
                "[兆候] " +
                x.newest.symptom
                  .filter((x) => x.detail != "その他")
                  .map((x) => x.detail + ", ")
                  .reduce((p, c) => p + c, "") +
                (x.newest.free_symptoms.length == 0
                  ? ""
                  : x.newest.free_symptoms
                      .map((x) => x + ", ")
                      .reduce((p, c) => p + c, "")) +
                "\n" +
                "[介入] " +
                intvStr +
                "\n\n";

              let status = "";
              if (closeNow) {
                status = "[プラン終了]";
              } else if (la) {
                const laids = la.newest.assessment_intervention.map(
                  (i) => i.intervention_id
                );
                const curids = x.newest.assessment_intervention.map(
                  (i) => i.intervention_id
                );
                let changed = false;
                curids.forEach((ci) => {
                  if (laids.findIndex((li) => li == ci) < 0) changed = true;
                });
                if (changed) status = "[プラン修正]";
                else status = "[プラン継続]";
              } else {
                status = "[プラン立案]";
              }
              values.problem_ass +=
                "# " +
                x.problem.name +
                status +
                "\n" +
                "K) " +
                x.newest.assessment.kbs_score_k +
                ":" +
                Model.Assessment.Assessment.getKBSSentence(
                  x.newest.assessment,
                  "K",
                  x.newest.assessment.kbs_score_k
                ) +
                "\n" +
                "B) " +
                x.newest.assessment.kbs_score_b +
                ":" +
                Model.Assessment.Assessment.getKBSSentence(
                  x.newest.assessment,
                  "B",
                  x.newest.assessment.kbs_score_b
                ) +
                "\n" +
                "S) " +
                x.newest.assessment.kbs_score_s +
                ":" +
                Model.Assessment.Assessment.getKBSSentence(
                  x.newest.assessment,
                  "S",
                  x.newest.assessment.kbs_score_s
                ) +
                "\n\n";
            }
          });
        }
        const pat = doc.patient;
        if (pat) {
          const names = Model.Patient.IryoKiguNames;
          let saigai =
            typeof pat.saigai == "string"
              ? JSON.parse(pat.saigai as any)
              : pat.saigai;
          if (saigai && saigai.kigus) {
            Object.keys(names).forEach((key) => {
              if (saigai.kigus[key]) {
                //if (values.comment != "") values.comment += " / ";
                //values.comment += names[key];
              }
            });
          }
        }
        sa.updateState();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    const patient = doc.patient;
    if (patient == null) return;

    doc.content.values.age = DateUtil.getAge(patient.birthday);
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    // 詳細な情報を得るためにpatientを引き直す
    HokanApi.getPatient(pat.id).then(async (res) => {
      const pat = await Model.Patient.Patient.load(res.data);
      doc.patient = pat;

      // 初期値を設定
      doc.content.values.birthday = pat.birthday;
      doc.content.values.gender = pat.value.sexuality;
      doc.content.values.youkaigo = pat.youkaigo;
      doc.content.values.address = `〒${pat.env.address_zip} ${pat.env.address1}${pat.env.address2}${pat.env.address3}`;
      doc.content.values.shubyoumei = pat.medical.sick_name;
      doc.content.values.shuhoken = pat.hoken.hoken_type;
      doc.content.values.honninnokimochi = pat.social.kibou_own;
      doc.content.values.kazokunokimochi = pat.social.kibou_famiry;
      doc.content.values.kazoku = pat.social.famiry.reduce(
        (accu, curr, i) =>
          accu + `No.${i + 1} 電話番号:${curr.tel} メモ:${curr.name}\n`,
        ""
      );
      doc.content.values.seiikureki = pat.social.seiiku;
      doc.content.values.yaminokiseki =
        pat.medical.kiou_reki === undefined ? "" : pat.medical.kiou_reki;
      if (pat.contact.shujii) {
        doc.content.values.sijisyo =
          pat.contact.shujii.org.name + pat.contact.shujii.name;
      }
      doc.content.values.yakuzai = pat.medical.yakuzai_kanri;

      doc.content.values.adl_ijyou = `${pat.medical.adl.ijou} ${pat.medical.adl.ijou_comment}`;
      doc.content.values.adl_idou = `${pat.medical.adl.idou} ${pat.medical.adl.idou_comment}`;
      doc.content.values.adl_kaidansyoukou = `${pat.medical.adl.kaidan} ${pat.medical.adl.kaidan_comment}`;
      doc.content.values.adl_syokuji = `${pat.medical.adl.shokuji} ${pat.medical.adl.shokuji_comment}`;
      doc.content.values.adl_nyuyoku = `${pat.medical.adl.nyuyoku} ${pat.medical.adl.nyuyoku_comment}`;
      doc.content.values.adl_toire = `${pat.medical.adl.toilet} ${pat.medical.adl.toilet_comment}`;
      doc.content.values.adl_hainyou = `${pat.medical.adl.hainyou} ${pat.medical.adl.hainyou_comment}`;
      doc.content.values.adl_haiben = `${pat.medical.adl.haiben} ${pat.medical.adl.haiben_comment}`;
      doc.content.values.adl_koui = `${pat.medical.adl.koui} ${pat.medical.adl.koui_comment}`;
      doc.content.values.adl_seiyou = `${pat.medical.adl.seiyou} ${pat.medical.adl.seiyou_comment}`;

      doc.content.values.iadl_denwa = `${pat.medical.iadl.denwa} ${pat.medical.iadl.denwa_comment}`;
      doc.content.values.iadl_kakei = `${pat.medical.iadl.kakei} ${pat.medical.iadl.kakei_comment}`;
      doc.content.values.iadl_kaimono = `${pat.medical.iadl.kaimono} ${pat.medical.iadl.kaimono_comment}`;
      doc.content.values.iadl_norimono = `${pat.medical.iadl.norimono} ${pat.medical.iadl.norimono_comment}`;
      doc.content.values.iadl_hukuyaku = `${pat.medical.iadl.hukuyak} ${pat.medical.iadl.hukuyak_comment}`;
      doc.content.values.iadl_syokuji_junbi = `${pat.medical.iadl.shokuji} ${pat.medical.iadl.shokuji_comment}`;
      doc.content.values.iadl_souji = `${pat.medical.iadl.souji} ${pat.medical.iadl.souji_comment}`;
      doc.content.values.iadl_sentaku = `${pat.medical.iadl.sentaku} ${pat.medical.iadl.sentaku_comment}`;

      sa.updateState();
    });
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(
    doc: Model.Document.Document,
    sa: StoreAccessor,
    classes: any
  ) {
    const saV = sa.generateChild(["content", "values"]);

    const element_basic = [
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              生年月日
              <div>{saV.date("birthday")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              性別
              <div>{saV.text("gender")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              住所
              <div style={{ flex: 1 }}>{saV.text("address")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              要介護度
              <div>{saV.text("youkaigo")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              主保険
              <div>{saV.text("shuhoken")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              主病名
              <div style={{ flex: 1 }}>{saV.text("shubyoumei")}</div>
            </label>
            <label>
              ジェノグラム
              {sa.docImage(1)}
            </label>
          </div>
        ),
      },
    ];

    const elements_t = [
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                本人の気持ちや希望
              </span>
              <div>
                {saV.text("honninnokimochi", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                家族の気持ちや希望
              </span>
              <div>
                {saV.text("kazokunokimochi", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                家族・キーパーソン
              </span>
              <div>{saV.text("kazoku", "", "text", "block", true, "80px")}</div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>生育歴</span>
              <div>
                {saV.text("seiikureki", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                病みの軌跡・既往歴・入院
              </span>
              <div>
                {saV.text("yaminokiseki", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                指示書の医師
              </span>
              <div>
                {saV.text("sijisyo", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                関係者やサービス種類
              </span>
              <div>
                {saV.text("kankeisya", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                処方された薬剤の管理
              </span>
              <div>
                {saV.text("yakuzai", "", "text", "block", true, "80px")}
              </div>
            </label>
            <h3>ADL</h3>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              移乗
              <div style={{ flex: 1 }}>{saV.text("adl_ijyou")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              移動
              <div style={{ flex: 1 }}>{saV.text("adl_idou")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              階段昇降
              <div style={{ flex: 1 }}>{saV.text("adl_kaidansyoukou")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              食事
              <div style={{ flex: 1 }}>{saV.text("adl_syokuji")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              入浴
              <div style={{ flex: 1 }}>{saV.text("adl_nyuyoku")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              トイレ
              <div style={{ flex: 1 }}>{saV.text("adl_toire")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              排尿
              <div style={{ flex: 1 }}>{saV.text("adl_hainyou")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              排便
              <div style={{ flex: 1 }}>{saV.text("adl_haiben")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              更衣
              <div style={{ flex: 1 }}>{saV.text("adl_koui")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              整容
              <div style={{ flex: 1 }}>{saV.text("adl_seiyou")}</div>
            </label>

            <h3>IADL</h3>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              電話の使用
              <div style={{ flex: 1 }}>{saV.text("iadl_denwa")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              家計の管理
              <div style={{ flex: 1 }}>{saV.text("iadl_kakei")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              買い物
              <div style={{ flex: 1 }}>{saV.text("iadl_kaimono")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              乗り物の利用
              <div style={{ flex: 1 }}>{saV.text("iadl_norimono")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              服薬管理
              <div style={{ flex: 1 }}>{saV.text("iadl_hukuyaku")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              食事の準備
              <div style={{ flex: 1 }}>{saV.text("iadl_syokuji_junbi")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              掃除などの家事
              <div style={{ flex: 1 }}>{saV.text("iadl_souji")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              洗濯
              <div style={{ flex: 1 }}>{saV.text("iadl_sentaku")}</div>
            </label>

            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                使用している医療機器
              </span>
              <div>
                {saV.text("iryoukiki", "", "text", "block", true, "80px")}
              </div>
            </label>

            <h3>看護上の問題とKBS（知識、行動、状態）</h3>
            <div style={{ marginBottom: 5 }}>
              <Button
                variant="outlined"
                size="small"
                onClick={(e) => this.loadFromAssessment(doc, sa)}
                className={classes.loadButton}
              >
                オマハから取得
              </Button>
            </div>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>日付</span>
              <div>
                {saV.text("problem_date", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>内容</span>
              <div>
                {saV.text("problem", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>評価</span>
              <div>
                {saV.text("problem_ass", "", "text", "block", true, "80px")}
              </div>
            </label>

            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                全体を通してのアセスメント
              </span>
              <div>
                {saV.text("asesumento", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                今後の見通し
              </span>
              <div>
                {saV.text("mitoosi", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label style={{ marginBottom: 10, display: "block" }}>
              <span style={{ marginBottom: 5, display: "block" }}>
                ケアの方向性
              </span>
              <div>
                {saV.text("kea_houkousei", "", "text", "block", true, "80px")}
              </div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              次回中間サマリー
              <div>{saV.date("jikai_date")}</div>
            </label>
          </div>
        ),
      },
    ];

    const elements_houkoku_moto = [
      {
        name: "コピー",
        content: (
          <div>
            <Button
              variant="outlined"
              size="small"
              onClick={(e) => this.loadFromBranch(doc, sa)}
              className={classes.loadButton}
            >
              事業所情報から取得
            </Button>
          </div>
        ),
      },
      { name: "事業所名", content: saV.text("jigyousyo") },
      { name: "住所", content: saV.text("jigyousyo_address") },
      { name: "電話", content: saV.text("jigyousyo_phone") },
      { name: "FAX", content: saV.text("jigyousyo_fax") },
      { name: "作成者", content: saV.text("sakuseisya") },
    ];

    const elements = [
      { name: "基本情報", content: element_basic },
      { name: "内容", content: elements_t },
      { name: "事業所", content: elements_houkoku_moto },
    ];

    return elements;
  }
}
